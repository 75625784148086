<template>
  <div class="h-100">
    <div
      v-if="
        isLoadingWasteCollectionPoint ||
        isUpdatingWasteCollectionPoint ||
        isDeletingWasteCollectionPoint
      "
      class="h-100"
    >
      <div class="text-center flex-center h-100">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div style="margin-top: 7rem">
          Chargement des détails du point de collecte...
        </div>
      </div>
    </div>

    <div v-else class="h-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>{{ wasteCollectionPoint.label }}</h3>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          @click.stop="closeSidebar"
          class="close-icon cursor-pointer"
        ></feather-icon>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-action py-05 px-2">
        <div class="d-flex align-items-center">
          <b-button
            v-if="archived"
            variant="warning"
            class="btn-icon"
            @click="
              restoreWasteCollectionPointLocal(
                wasteCollectionPoint.id,
                wasteCollectionPoint.name
              )
            "
          >
            <feather-icon icon="ArrowDownIcon" /> Restaurer
          </b-button>
          <b-button
            v-else
            class="btn-icon"
            variant="primary"
            @click="editWasteCollectionPoint(wasteCollectionPoint.id)"
            :disabled="archived"
          >
            <feather-icon icon="EditIcon" /> Modifier
          </b-button>
        </div>
        <b-button-group>
          <b-button
            v-if="archived"
            variant="outline-danger"
            class="btn-icon"
            @click="
              deleteWasteCollectionPointLocal(
                wasteCollectionPoint.id,
                wasteCollectionPoint.name
              )
            "
          >
            <feather-icon icon="TrashIcon" /> Supprimer définitivement
          </b-button>
          <b-button
            v-else
            variant="outline-danger"
            class="btn-icon"
            @click="
              archiveWasteCollectionPointLocal(
                wasteCollectionPoint.id,
                wasteCollectionPoint.name
              )
            "
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </b-button-group>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-content">
        <div>
          <h4 class="mb-1 text-primary">
            <feather-icon icon="MapPinIcon" /> Adresse
          </h4>
          <p class="paraphbox">
            <span v-show="wasteCollectionPoint.address"
              >{{ wasteCollectionPoint.address }}<br
            /></span>
            <span v-show="wasteCollectionPoint.addressComplement"
              >{{ wasteCollectionPoint.addressComplement }}<br
            /></span>
            <span
              v-show="wasteCollectionPoint.zipCode || wasteCollectionPoint.city"
              >{{ wasteCollectionPoint.zipCode }} {{ wasteCollectionPoint.city
              }}<br
            /></span>
            <span v-show="wasteCollectionPoint.country">{{
              wasteCollectionPoint.country
            }}</span>
          </p>
          <vs-divider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      archived: false,
      wasteCollectionPoint: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingWasteCollectionPoint",
      "wasteCollectionPointsList",
      "isLoadingWasteCollectionPoint",
      "isDeletingWasteCollectionPoint",
    ]),
  },
  methods: {
    ...mapActions([
      "deleteWasteCollectionPoints",
      "archiveWasteCollectionPoints",
      "getWasteCollectionPointById",
      "getWasteCollectionPointArchivedById",
      "restoreWasteCollectionPoints",
    ]),
    getWasteCollectionPointArchivedDetails(id, archived) {
      this.archived = archived;
      if (id != "") {
        this.getWasteCollectionPointArchivedById({
          wasteCollectionPointId: id,
        }).then((res) => {
          this.wasteCollectionPoint = res;
        });
      } else {
        this.wasteCollectionPoint = {};
      }
    },
    getWasteCollectionPointDetails(id) {
      this.archived = false;
      if (id != "") {
        this.getWasteCollectionPointById({ wasteCollectionPointId: id }).then(
          (res) => {
            this.wasteCollectionPoint = res;
          }
        );
      } else {
        this.wasteCollectionPoint = {};
      }
    },
    async editWasteCollectionPoint(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-waste-collection-point",
        params: {
          id: id,
          title: "Edit : " + this.wasteCollectionPoint.label,
          tips: "Editer le point : " + this.wasteCollectionPoint.label,
          routeOrigine: "waste-collection-points",
        },
      });
    },

    archiveWasteCollectionPointLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce point de collecte en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le point de collecte "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveWasteCollectionPoints({
              wasteCollectionPointIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    restoreWasteCollectionPointLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le point de collecte "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreWasteCollectionPoints({
              wasteCollectionPointIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteWasteCollectionPointLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer le point de collecte "' +
            label +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteWasteCollectionPoints({
              wasteCollectionPointIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>
